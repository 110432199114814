import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import ServicesBlock from '../components/Home/ServicesBlock_2'
import BlockHomeTwo from "../components/Home/BlockHomeTwo";

function Home() {
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <BlockHomeOne />
        <Directories />
        <BlockAboutOne />
        <div className="mt-[10px]">
          <Values />
        </div> 
        <BlockHomeTwo/>
        <ServicesBlock/>
        <div className="md:my-6 md:px-14">
          <h2 className="text-center">what our customers say</h2>
            <div class="elfsight-app-8464fd87-e0d0-42a6-877b-8a82a88e9163 md:px-48 md:my-8 "></div>
        </div>
        {/* <ServicesHome /> */}
        <div className="fondo3 py-[100px]">
          <FormHome />
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
